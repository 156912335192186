import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Collapse, Tooltip, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, Report, Folder, Favorite, DataArray, Chat } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


// Define the structure for the sidebar items
const sidebarConfig = [
    { text: "Overview", Icon: Report },
    { text: "All reports", Icon: Report, secondaryText: "22" },
    { text: "My reports", Icon: Report, secondaryText: "0" },
    { text: "Data export", Icon: DataArray },
    {
        text: "My favorites", Icon: Favorite, expandable: true, children: [
            { text: "No reports added" }
        ]
    },
    {
        text: "Workspaces", expandable: true, children: [
            { text: "Human support", Icon: Folder },
            { text: "Automation", Icon: Folder },
            { text: "Proactive support", Icon: Folder }
        ]
    },
    { text: "Conversation topics", Icon: Chat }
];

export const Sidebar = ({title, sidebarConfig}) => {
    const [open, setOpen] = useState(() => {
        const savedState = localStorage.getItem('sidebarState');
        if (savedState) return JSON.parse(savedState);

        // Default open state initialization if nothing is stored in localStorage
        const initialOpenState = {};
        sidebarConfig.forEach(item => {
            if (item.children) {
                initialOpenState[item.text] = true;  // Set all expandable items to be open by default
            }
            item.children?.forEach(subItem => {
                if (subItem.children) {
                    initialOpenState[subItem.text] = true;
                }
            });
        });
        return initialOpenState;
    });

    // Update localStorage when the `open` state changes
    useEffect(() => {
        localStorage.setItem('sidebarState', JSON.stringify(open));
    }, [open]);
    const handleClick = (item) => {
        setOpen(prev => ({ ...prev, [item]: !prev[item] }));
    };

    return (
        <Box sx={{
            width: '100%',
            minWidth: 150,
            maxWidth: 200,
            height: '100vh',
            bgcolor: '#F5F5F5',
            overflow: 'auto',
            fontSize: '0.75rem',
            boxShadow: '1px 0 1px rgba(0,0,0,0.1)' // Shadow on the right side of the Box
        }}>

            <Typography variant="h6" gutterBottom component="div"
                        style={{textAlign:'center', margin: '20px auto 10px'}}>
                {title}
            </Typography>
            <List component="nav" aria-labelledby="nested-list-subheader">
                {sidebarConfig && sidebarConfig?.map((item, index) => (
                    <React.Fragment key={index}>
                        <Tooltip title={item.tooltip} arrow key={index} placement={'bottom'}>
                        <ListItem button
                                  onClick={() => item.children && handleClick(item.text)}
                                  sx={{
                                      py: 0.5,
                                      '&:hover': {
                                          bgcolor: 'white' // Background color on hover
                                      },
                                      ...(open[item.text] && {
                                          bgcolor: 'white' // Background color when active
                                      })
                                  }}>
                            {item.Icon && <ListItemIcon sx={{ minWidth: '36px' }}><item.Icon fontSize="small" /></ListItemIcon>}
                            <ListItemText primary={item.text} secondary={item.secondaryText} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                            {item?.children && (open[item.text] ? <ExpandLess /> : <ExpandMore />)}
                        </ListItem></Tooltip>
                        {item.children && (
                            <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding style={{ maxHeight: 300, overflowY: 'auto' }}>
                                    {item.children.map((subItem, subIndex) => (
                                        <Tooltip title={subItem.tooltip} arrow key={subItem.tooltip + subIndex} placement={'bottom'}>
                                            <ListItem button sx={{ pl: 4, py: 0.5 }}
                                                      component={subItem.path ? NavLink : 'div'}
                                                      to={subItem.path}>
                                                {subItem.Icon && <ListItemIcon sx={{ minWidth: '36px' }}><subItem.Icon fontSize="small" /></ListItemIcon>}
                                                <ListItemText primary={subItem.text} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                            </ListItem>
                                        </Tooltip>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default Sidebar;
