import {
    AccountTree as AccountTreeIcon,
    Add as AddIcon,
    Info as InfoIcon,
    IntegrationInstructions as IntegrationInstructionsIcon,
    People as PeopleIcon,
    SettingsApplications as SettingsApplicationsIcon,
    Storage as StorageIcon,
    Search as SearchIcon,
    Tune, SmartToy,
    AddBox, Assessment,
    SupervisedUserCircle,
    Cloud,
    CloudUpload,
    CloudDone,
    CloudCircle,
    CloudQueue,
    Share,
    FlashOn,
    Memory,
    DatasetLinked as Database,
    PostAdd,
    Grain,
    Public,
    TableView,
    Category,
    Chat,
    QuestionAnswer,
    InsertDriveFile,
    Event,
    Business,
    Person,
    Place,
    Groups,
    Email,
    SettingsSuggest,
    Dashboard,
    LocalShipping,
    PhotoLibrary,
    ShoppingBasket,
    Settings,
    Language,
    Insights,
    IntegrationInstructionsSharp as IntegrationInstructions,
    Chat as ChatIcon,
    PictureAsPdf,
    TextSnippet,
    Phone, Web, Campaign, Quiz, DynamicForm, FactCheck, AddHome, AutoDelete, ScheduleSend, AdsClick
} from '@mui/icons-material'
import React from 'react'

const menuItems = [
    {
        icon: <SearchIcon />,
        tooltip: 'Search the Index',
        text: 'Search',
        slug: 'search',
        category: 'data-management',
        path: '/search',
    },
    {
        icon: <ChatIcon />,
        tooltip: 'Chat with AI',
        text: 'Chat',
        slug: 'chat',
        category: 'data-management',
        path: '/chat',
    },
    {
        icon: <Web />,
        tooltip: 'Manage Landing Pages',
        text: 'Landing Pages',
        slug: 'landing_pages',
        category: 'creations',
        path: '/landing_pages',
        children: [
            {
                icon: <AddHome />,
                text: 'Eligible for Homepage',
                path: '/landing_pages/homepage',
            },
            {
                icon: <AutoDelete />,
                text: 'Archived Landing Pages',
                path: '/landing_pages/archived',
            },
            { icon: <AddIcon />, text: 'Create Landing Page', path: '/landing_pages/add' },
        ],
    },
    {
        icon: <Campaign />,
        tooltip: 'Manage Campaigns',
        text: 'Campaigns',
        slug: 'campaigns',
        category: 'creations',
        path: '/campaigns',
    children: [
    {
        icon: <AdsClick />,
        text: 'Active Campaigns',
        path: '/campaigns/active',
    },
    {
        icon: <ScheduleSend />,
        text: 'Scheduled Campaigns',
        path: '/campaigns/scheduled',
    },
    {
        icon: <AutoDelete />,
        text: 'Archived Campaigns',
        path: '/campaigns/archived',
    },
    { icon: <AddIcon />, text: 'Create Campaign', path: '/campaigns/add' },
],
    },
    {
        icon: <Quiz />,
        tooltip: 'Manage Engagement Questions People may be asked',
        text: 'Questions',
        slug: 'questions',
        category: 'creations',
        path: '/questions',
    },
    {
        icon: <DynamicForm />,
        tooltip: 'Manage Forms People might encounter',
        text: 'Forms',
        slug: 'forms',
        category: 'creations',
        path: '/forms',
        children: [
            {
                icon: <FactCheck />,
                text: 'Form Submissions',
                path: '/form-submissions',
            },
            { icon: <AddIcon />, text: 'Create Form', path: '/forms/add' },
        ],
    },
    {
        icon: <AccountTreeIcon />,
        tooltip: 'Browse the Knowledge Base',
        text: 'Knowledge Base',
        slug: 'knowledge-base',
        category: 'data-management',
        path: '/browse',
        children: [
            {
                icon: <Person />,
                tooltip: 'Individual person, alive, dead, undead, or fictional',
                text: 'People',
                path: '/browse/people',
            },
            {
                icon: <Place />,
                tooltip: 'Geographical locations, including continents, bodies of water, and buildings',
                text: 'Places',
                path: '/browse/places',
            },
            {
                icon: <Chat />,
                tooltip: 'Conversations and direct communications',
                text: 'Conversations',
                path: '/browse/communications',
            },
            {
                icon: <QuestionAnswer />,
                tooltip: 'Questions and answers, used in FAQs and info pages',
                text: 'Question & Answers',
                path: '/browse/questions_&_answers',
            },
            {
                icon: <PictureAsPdf />,
                tooltip: 'Creative works like documents',
                text: 'Documents',
                path: '/browse/documents',
            },
            {
                icon: <LocalShipping />,
                tooltip: 'Cars, boats, trucks, and other vessels',
                text: 'Vehicles',
                path: '/browse/vehicles',
            },
            {
                icon: <Business />,
                tooltip: 'Companies, non-profits, schools, and other organizations',
                text: 'Organizations',
                path: '/browse/organizations',
            },
            {
                icon: <PhotoLibrary />,
                tooltip: 'Creative works like books, movies, and music',
                text: 'Media',
                path: '/browse/creative-work',
            },
            {
                icon: <Event />,
                tooltip: 'Events like concerts and sports',
                text: 'Events',
                path: '/browse/events',
            },
            {
                icon: <ShoppingBasket />,
                tooltip: 'Products and services, including non-physical items',
                text: 'Products',
                path: '/browse/products',
            },
            {
                icon: <Public />,
                tooltip: 'Pages found across the Web',
                text: 'Websites',
                path: '/browse/websites',
            }
        ]
    },
/*
    {
        icon: <AccountTreeIcon />,
        tooltip: 'Manage Indexes / LIMs',
        text: 'Indexes',
        slug: 'index-management',
        category: 'data-management',
        path: '/indexes',
        children: [
            {
                icon: <Tune />,
                tooltip: 'Manage specific index',
                text: 'Manage Index',
                path: '/indexes/manage',
            },
            {
                icon: <SupervisedUserCircle />,
                tooltip: 'Manage entities within indexes',
                text: 'Manage Entities',
                path: '/indexes/manage/entities',
            },
            {
                icon: <AddBox />,
                tooltip: 'Create a new index',
                text: 'Create Index',
                path: '/indexes/create'
            },
        ],
    },
*/
    {
        icon: <StorageIcon />,
        tooltip: 'Manage Data Sources',
        text: 'Data Sources',
        slug: 'datastores',
        category: 'models-actions',
        path: '/sources',
        children: [
            {
                icon: <TextSnippet />,
                tooltip: 'Manage Text Snippets',
                text: 'Text Snippets',
                slug: 'text-snippets',
                path: '/sources/snippets'
            },
            {
                icon: <PictureAsPdf />,
                tooltip: 'Manage synced Documents',
                text: 'Documents',
                slug: 'synced-documents',
                path: '/sources/documents'
            },
            {
                icon: <Public />,
                tooltip: 'Manage synced Websites',
                text: 'Websites',
                slug: 'synced-websites',
                path: '/sources/websites',
                children: [
                    {
                        icon: <CloudUpload />,
                        tooltip: 'Manage Notion syncing',
                        text: 'Notion',
                        path: '/sources/websites/notion',
                    },
                    {
                        icon: <CloudDone />,
                        tooltip: 'Manage Zendesk syncing',
                        text: 'Zendesk',
                        path: '/sources/websites/zendesk',
                    },
                    {
                        icon: <CloudCircle />,
                        tooltip: 'Manage Confluence syncing',
                        text: 'Confluence',
                        path: '/sources/websites/confluence',
                    },
                ],
            },
            {
                icon: <Cloud />,
                tooltip: 'Manage cloud storage solutions',
                text: 'Cloud Storage',
                slug: 'cloud-storage',
                path: '/sources/cloud',
                children: [
                    {
                        icon: <CloudUpload />,
                        tooltip: 'Manage Amazon S3 buckets',
                        text: 'Amazon S3',
                        path: '/sources/cloud/s3',
                    },
                    {
                        icon: <CloudDone />,
                        tooltip: 'Manage Google Cloud Storage',
                        text: 'Google Cloud Storage',
                        path: '/sources/cloud/gcs',
                    },
                    {
                        icon: <CloudCircle />,
                        tooltip: 'Manage Azure Blob Storage',
                        text: 'Azure Blob Storage',
                        path: '/sources/cloud/azure-blob',
                    },
                ],
            },
            {
                icon: <Database />,
                tooltip: 'Manage Non / Relational Databases',
                text: 'Databases',
                slug: 'relational-dbs',
                path: '/sources/databases',
                children: [
                    {
                        icon: <PostAdd />,
                        tooltip: 'Manage PostgreSQL databases',
                        text: 'PostgreSQL',
                        path: '/postgres',
                    },
                    {
                        icon: <StorageIcon />,
                        tooltip: 'Manage MySQL databases',
                        text: 'MySQL',
                        path: '/mysql',
                    },
                    {
                        icon: <Grain />,
                        tooltip: 'Manage MongoDB databases',
                        text: 'MongoDB',
                        path: '/mongodb',
                    },
                    {
                        icon: <Memory />,
                        tooltip: 'Manage Cassandra databases',
                        text: 'Cassandra',
                        path: '/cassandra',
                    },
                    {
                        icon: <FlashOn />,
                        tooltip: 'Manage Redis stores',
                        text: 'Redis',
                        path: '/redis',
                    },
                    {
                        icon: <CloudQueue />,
                        tooltip: 'Manage Amazon DynamoDB stores',
                        text: 'Amazon DynamoDB',
                        path: '/dynamodb',
                    },
                    {
                        icon: <Share />,
                        tooltip: 'Manage Neo4j graph databases',
                        text: 'Neo4j',
                        path: '/neo4j',
                    },
                ],
            },
        ],
    },
    {
        icon: <SettingsApplicationsIcon />,
        tooltip: 'Manage Vision, Voice, TTS, Language Models / LLMs & other AI models',
        text: 'AI Models',
        path: 'models',
        slug: 'ai-models',
        category: 'models-actions',
        children: [
            {
                icon: <SettingsApplicationsIcon />,
                text: 'OpenAI',
                slug: 'openai',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'GPT-4o-mini',
                        path: '/gpt-4o-mini',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'GPT-4o',
                        path: '/gpt-4o',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'GPT-4',
                        path: '/gpt-4',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'GPT-3.5',
                        path: '/gpt-3.5',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Whisper',
                        path: '/whisper',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'DALL-E 3',
                        path: '/dall-e-3',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Clip',
                        path: '/clip',
                    }
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Anthropic',
                slug: 'anthropic',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Claude 3.5',
                        path: '/claude-3.5',
                    },
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Google DeepMind',
                slug: 'google-deepmind',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Gemini 1.5',
                        path: '/gemini-1.5',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Imagen 3',
                        path: '/imagen-3',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'MusicFX',
                        path: '/musicfx',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'VideoFX',
                        path: '/videofx',
                    }
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Hugging Face',
                slug: 'hugging-face',
                tooltip: 'Explore Hugging Face’s latest models and collaborations, including advancements in language and vision AI technologies.',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'SmolLM',
                        path: '/smollm',
                        tooltip: 'SmolLM represents a shift towards efficient, on-device AI processing, promoting privacy and reduced latency in applications.'
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Phi-3',
                        path: '/phi-3',
                        tooltip: 'Phi-3 models are designed for high performance with lower parameter counts, suitable for on-device applications.'
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Mistral-Nemo',
                        path: '/mistral-nemo',
                        tooltip: 'A collaboration with Nvidia, Mistral-Nemo is optimized for desktop use with impressive parameter efficiency and token context windows.'
                    }
                ],
            },
            {
                "icon": "<SettingsApplicationsIcon />",
                "text": "IBM",
                "slug": "ibm",
                "children": [
                    {
                        "icon": "<SettingsApplicationsIcon />",
                        "text": "Watson NLP",
                        "path": "/watson-nlp"
                    }
                ]
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Meta AI',
                slug: 'meta-ai',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Llama 3.1',
                        path: '/llama-3.1',
                    },
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Mistral AI',
                slug: 'mistral-ai',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Mixtral 8x22B',
                        path: '/mixtral-8x22b',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Mistral 7B Instruct v0.3',
                        path: '/mistral-7b-instruct-v0.3',
                    },
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Microsoft',
                slug: 'microsoft',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Azure Uni-TTSv4',
                        path: '/uni-ttsv4',
                    }
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'NVIDIA',
                slug: 'nvidia',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'StyleGAN 3',
                        path: '/stylegan-3',
                    }
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Inflection AI',
                slug: 'inflection-ai',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Inflection-2.5',
                        path: '/inflection-2.5',
                    },
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'AI21 Labs',
                slug: 'ai21-labs',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Jamba',
                        path: '/jamba',
                    },
                ],
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Cohere',
                slug: 'cohere',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Command R',
                        path: '/command-r',
                    },
                ],
            },,
            {
                icon: <SettingsApplicationsIcon />,
                text: 'xAI',
                slug: 'xai',
                children: [
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Grok 2',
                        path: '/grok-2.0',
                    },
                    {
                        icon: <SettingsApplicationsIcon />,
                        text: 'Grok',
                        path: '/grok-1.0',
                    },
                ],
            },
        ],
    },
/*
    {
        icon: <IntegrationInstructionsIcon />,
        tooltip: 'Manage Integrations',
        text: 'Integrations',
        slug: 'integrations',
        path: 'integrations',
        category: 'models-actions',
        children: [
            {
                icon: <Groups />,
                text: 'CRM Platforms',
                slug: 'crm-platforms',
                path: 'integrations/crm',
                children: [
                    {
                        icon: <Phone />,
                        text: 'Calabrio',
                        slug: 'calabrio',
                        path: '/calabrio',
                        entity_summary: {
                            entity_type: 'integration',
                            details: [
                                {
                                    icon: <Email />,
                                    primary: 'Calabrio',
                                    secondary: 'Calabrio integrates with Umbrella AI to easily sync your Conversations, Agents, & Contacts',
                                    slug: 'integration_scalabrio'
                                },
                                {
                                    icon: <Email />,
                                    primary: 'Phone Call Sync',
                                    secondary: 'Synchronize phone calls in to CRM records',
                                    slug: 'email_sync'
                                },
                                {
                                    icon: <Settings />,
                                    primary: 'Workflow Automation',
                                    secondary: 'Automate routine Calabrio tasks',
                                    slug: 'workflow_automation'
                                },
                                {
                                    icon: <Language />,
                                    primary: 'Language Translation',
                                    secondary: 'Support for multiple languages in Calabrio',
                                    slug: 'language_translation'
                                },
                                {
                                    icon: <Insights />,
                                    primary: 'Actionable Insights',
                                    secondary: 'Generate actionable insights from Calabrio data',
                                    slug: 'actionable_insights'
                                },
                                {
                                    icon: <Memory />,
                                    primary: 'Data Indexing',
                                    secondary: 'Enhanced data indexing with AI',
                                    slug: 'data_indexing'
                                }
                            ]
                        }
                    },
                    {
                        icon: <Groups />,
                        text: 'Salesforce',
                        slug: 'salesforce',
                        path: '/salesforce',
                        entity_summary: {
                            entity_type: 'integration',
                            details: [
                                {
                                    icon: <IntegrationInstructions />,
                                    primary: 'Salesforce',
                                    secondary: 'Salesforce integrates with Umbrella AI to easily sync your Contacts',
                                    slug: 'api_integration'
                                },
                                {
                                    icon: <Email />,
                                    primary: 'Email Sync',
                                    secondary: 'Synchronize emails with CRM records',
                                    slug: 'email_sync'
                                },
                                {
                                    icon: <Settings />,
                                    primary: 'Workflow Automation',
                                    secondary: 'Automate routine Salesforce tasks',
                                    slug: 'workflow_automation'
                                },
                                {
                                    icon: <Language />,
                                    primary: 'Language Translation',
                                    secondary: 'Support for multiple languages in Salesforce',
                                    slug: 'language_translation'
                                },
                                {
                                    icon: <Insights />,
                                    primary: 'Actionable Insights',
                                    secondary: 'Generate actionable insights from Salesforce data',
                                    slug: 'actionable_insights'
                                },
                                {
                                    icon: <Memory />,
                                    primary: 'Data Indexing',
                                    secondary: 'Enhanced data indexing with AI',
                                    slug: 'data_indexing'
                                }
                            ]
                        }
                    },
                    {
                        icon: <Groups />,
                        text: 'HubSpot CRM',
                        slug: 'hubspot',
                        path: '/hubspot-crm',
                        entity_summary: {
                            entity_type: 'integration',
                            details: [
                                {
                                    icon: <IntegrationInstructions />,
                                    primary: 'Hubspot CRM',
                                    secondary: 'Hubspot CRM integrates with Umbrella AI to easily sync your Contacts',
                                    slug: 'api_integration'
                                },
                                {
                                    icon: <Email />,
                                    primary: 'Email Sync',
                                    secondary: 'Synchronize emails with CRM records',
                                    slug: 'email_sync'
                                },
                                {
                                    icon: <Settings />,
                                    primary: 'Workflow Automation',
                                    secondary: 'Automate routine Salesforce tasks',
                                    slug: 'workflow_automation'
                                },
                                {
                                    icon: <Language />,
                                    primary: 'Language Translation',
                                    secondary: 'Support for multiple languages in Salesforce',
                                    slug: 'language_translation'
                                },
                                {
                                    icon: <Insights />,
                                    primary: 'Actionable Insights',
                                    secondary: 'Generate actionable insights from Salesforce data',
                                    slug: 'actionable_insights'
                                },
                                {
                                    icon: <Memory />,
                                    primary: 'Data Indexing',
                                    secondary: 'Enhanced data indexing with AI',
                                    slug: 'data_indexing'
                                }
                            ]
                        }
                    },
                    {
                        icon: <Groups />,
                        text: 'Zoho CRM',
                        slug: 'zoho',
                        path: '/zoho-crm',
                        entity_summary: {
                            entity_type: 'integration',
                            details: [
                                {
                                    icon: <IntegrationInstructions />,
                                    primary: 'Zoho CRM',
                                    secondary: 'Zoho CRM integrates with Umbrella AI to easily sync your Contacts',
                                    slug: 'api_integration'
                                },
                                {
                                    icon: <Email />,
                                    primary: 'Email Sync',
                                    secondary: 'Synchronize emails with CRM records',
                                    slug: 'email_sync'
                                },
                                {
                                    icon: <Settings />,
                                    primary: 'Workflow Automation',
                                    secondary: 'Automate routine Salesforce tasks',
                                    slug: 'workflow_automation'
                                },
                                {
                                    icon: <Language />,
                                    primary: 'Language Translation',
                                    secondary: 'Support for multiple languages in Salesforce',
                                    slug: 'language_translation'
                                },
                                {
                                    icon: <Insights />,
                                    primary: 'Actionable Insights',
                                    secondary: 'Generate actionable insights from Salesforce data',
                                    slug: 'actionable_insights'
                                },
                                {
                                    icon: <Memory />,
                                    primary: 'Data Indexing',
                                    secondary: 'Enhanced data indexing with AI',
                                    slug: 'data_indexing'
                                }
                            ]
                        }
                    },
                    {
                        icon: <Groups />,
                        text: 'Pipedrive',
                        slug: 'pipedrive',
                        path: '/pipedrive',
                        entity_summary: {
                            entity_type: 'integration',
                            details: [
                                {
                                    icon: <IntegrationInstructions />,
                                    primary: 'Pipedrive',
                                    secondary: 'Pipedrive integrates with Umbrella AI to easily sync your Contacts',
                                    slug: 'api_integration'
                                },
                                {
                                    icon: <Email />,
                                    primary: 'Email Sync',
                                    secondary: 'Synchronize emails with CRM records',
                                    slug: 'email_sync'
                                },
                                {
                                    icon: <Settings />,
                                    primary: 'Workflow Automation',
                                    secondary: 'Automate routine Salesforce tasks',
                                    slug: 'workflow_automation'
                                },
                                {
                                    icon: <Language />,
                                    primary: 'Language Translation',
                                    secondary: 'Support for multiple languages in Salesforce',
                                    slug: 'language_translation'
                                },
                                {
                                    icon: <Insights />,
                                    primary: 'Actionable Insights',
                                    secondary: 'Generate actionable insights from Salesforce data',
                                    slug: 'actionable_insights'
                                },
                                {
                                    icon: <Memory />,
                                    primary: 'Data Indexing',
                                    secondary: 'Enhanced data indexing with AI',
                                    slug: 'data_indexing'
                                }
                            ]
                        }
                    },
                    {
                        icon: <Groups />,
                        text: 'SendBoth',
                        slug: 'sendBoth',
                        path: '/sendboth',
                        entity_summary: {
                            entity_type: 'integration',
                            details: [
                                {
                                    icon: <Email />,
                                    primary: 'SendBoth',
                                    secondary: 'Cell',
                                    slug: 'integration_sendBoth'
                                },
                                {
                                    icon: <Email />,
                                    primary: 'Email Sync',
                                    secondary: 'Synchronize emails with CRM records',
                                    slug: 'email_sync'
                                },
                                {
                                    icon: <Settings />,
                                    primary: 'Workflow Automation',
                                    secondary: 'Automate routine Salesforce tasks',
                                    slug: 'workflow_automation'
                                },
                                {
                                    icon: <Language />,
                                    primary: 'Language Translation',
                                    secondary: 'Support for multiple languages in Salesforce',
                                    slug: 'language_translation'
                                },
                                {
                                    icon: <Insights />,
                                    primary: 'Actionable Insights',
                                    secondary: 'Generate actionable insights from Salesforce data',
                                    slug: 'actionable_insights'
                                },
                                {
                                    icon: <Memory />,
                                    primary: 'Data Indexing',
                                    secondary: 'Enhanced data indexing with AI',
                                    slug: 'data_indexing'
                                }
                            ]
                        }
                    },
                ],
            },
            {
                icon: <Email />,
                text: 'Email Marketing',
                slug: 'email-marketing',
                path: 'integrations/email',
                children: [
                    {
                        icon: <Email />,
                        text: 'SendBoth',
                        path: '/sendboth',
                        slug: 'sendboth',
                    },
                    {
                        icon: <Email />,
                        text: 'Mailchimp',
                        path: '/mailchimp',
                        slug: 'mailchimp',
                    },
                    {
                        icon: <Email />,
                        text: 'SendGrid',
                        path: '/sendgrid',
                        slug: 'sendgrid',
                    },
                    {
                        icon: <Email />,
                        text: 'Mailgun',
                        path: '/mailgun',
                        slug: 'mailgun',
                    },
                    {
                        icon: <Email />,
                        text: 'ActiveCampaign',
                        path: '/activecampaign',
                        slug: 'activecampaign',
                    },
                ],
            },
            {
                icon: <SettingsSuggest />,
                text: 'Automation Tools',
                slug: 'automation-tools',
                path: 'integrations/automation',
                children: [
                    {
                        icon: <SettingsSuggest />,
                        text: 'Zapier',
                        path: '/zapier',
                        slug: 'zapier',
                    },
                    {
                        icon: <SettingsSuggest />,
                        text: 'Integromat',
                        path: '/integromat',
                        slug: 'integromat',
                    },
                ],
            },
            {
                icon: <Dashboard />,
                text: 'Productivity Apps',
                slug: 'productivity-apps',
                path: 'integrations/productivity',
                children: [
                    {
                        icon: <Dashboard />,
                        text: 'Slack',
                        path: '/slack',
                        slug: 'slack',
                    },
                    {
                        icon: <Dashboard />,
                        text: 'Google Sheets',
                        path: '/google-sheets',
                        slug: 'google-sheets',
                    },
                    {
                        icon: <Dashboard />,
                        text: 'Microsoft Teams',
                        path: '/microsoft-teams',
                        slug: 'microsoft-teams',
                    },
                    {
                        icon: <Dashboard />,
                        text: 'Discord',
                        path: '/discord',
                        slug: 'discord',
                    },
                ],
            },
            {
                icon: <StorageIcon />,
                text: 'Data Management',
                slug: 'data-management',
                path: 'integrations/data',
                children: [
                    {
                        icon: <StorageIcon />,
                        text: 'Data Warehousing and Storage',
                        slug: 'data-warehousing',
                        children: [
                            {
                                icon: <StorageIcon />,
                                text: 'Calabrio',
                                path: '/calabrio',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'Google BigQuery',
                                path: '/google-bigquery',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'AWS Redshift',
                                path: '/aws-redshift',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'Snowflake',
                                path: '/snowflake',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'Azure SQL Data Warehouse',
                                path: '/azure-sql-data-warehouse',
                            },
                        ],
                    },
                    {
                        icon: <StorageIcon />,
                        text: 'Data Streaming and Messaging',
                        slug: 'data-streaming',
                        children: [
                            {
                                icon: <StorageIcon />,
                                text: 'Apache Kafka',
                                path: '/apache-kafka',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'RabbitMQ',
                                path: '/rabbitmq',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'AWS Kinesis',
                                path: '/aws-kinesis',
                            },
                        ],
                    },
                    {
                        icon: <StorageIcon />,
                        text: 'Search and Analysis',
                        slug: 'search-analysis',
                        children: [
                            {
                                icon: <StorageIcon />,
                                text: 'Elasticsearch',
                                path: '/elasticsearch',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'Google Dataflow',
                                path: '/google-dataflow',
                            },
                        ],
                    },
                    {
                        icon: <StorageIcon />,
                        text: 'Data Visualization and Reporting',
                        slug: 'data-visualization',
                        children: [
                            {
                                icon: <StorageIcon />,
                                text: 'Power BI',
                                path: '/power-bi',
                            },
                            {
                                icon: <StorageIcon />,
                                text: 'Tableau',
                                path: '/tableau',
                            },
                        ],
                    },
                ],
            },
        ],
    },
*/
    {
        icon: <Category />,
        tooltip: 'Manage Automations, Actions / LAMs',
        text: 'Automations',
        slug: 'actions',
        path: 'actions',
        category: 'models-actions',
        children: [
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Manage Actions',
                slug: 'manage_actions',
                path: '/actions/manage',
                entity_summary: {
                    entity_type: 'actions',
                    details: [
                        {
                            icon: <Memory />,
                            primary: 'Document Processing',
                            secondary: 'Enhanced data indexing with AI',
                            slug: 'data_indexing'
                        },
                        {
                            icon: <Insights />,
                            primary: 'Actionable Insights',
                            secondary: 'Generate actionable insights from Calabrio data',
                            slug: 'actionable_insights'
                        },
                    ]
                }
            },
            { icon: <AddIcon />, text: 'Create Action', path: '/actions/create' },
        ],
    },
    {
        icon: <SmartToy />,
        tooltip: 'Manage Agents / AI Employees',
        text: 'Agents',
        slug: 'agents',
        path: 'agents',
        category: 'models-actions',
        children: [
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Manage Agents',
                slug: 'manage_agents',
                path: '/agents/manage',
                entity_summary: {
                    entity_type: 'agents',
                    details: [
                        {
                            icon: <Memory />,
                            primary: 'Aaria',
                            secondary: 'The AAA AI Employee',
                            slug: 'agent_aaria'
                        },
                        {
                            icon: <Insights />,
                            primary: 'Actionable Insights',
                            secondary: 'Generate actionable insights from Calabrio data',
                            slug: 'actionable_insights'
                        },
                    ]
                }
            },
            { icon: <AddIcon />, text: 'Create Agent', path: '/agents/create' },
        ],
    },
    {
        icon: <Assessment />,
        tooltip: 'Metrics, Usage & Insights',
        text: 'Reports',
        slug: 'reports',
        path: 'reports',
        category: 'org-management',
    },
    {
        icon: <PeopleIcon />,
        tooltip: 'Manage Organization',
        text: 'Organization',
        slug: 'organization',
        path: 'organization',
        category: 'org-management',
        children: [
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Manage Organizations',
                path: 'organization/manage',
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Manage Users',
                path: 'organization/manage/users',
            },
            {
                icon: <AddIcon />,
                text: 'Create Organization',
                path: 'organization/create',
            },
            { icon: <AddIcon />, text: 'Create User', path: '/organization/create/users' },
        ],
    },
    {
        disabled: true,
        icon: <InfoIcon />,
        text: 'Support',
        slug: 'support',
        category: 'org-management',
        children: [
            {
                icon: <SettingsApplicationsIcon />,
                text: 'FAQs',
                path: '/select-index',
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Documentation',
                path: '/select-index',
            },
            {
                icon: <SettingsApplicationsIcon />,
                text: 'Support Tickets',
                path: '/select-index',
            },
            { icon: <AddIcon />, text: 'Contact', path: '/select-index' },
        ],
    },
]

export default menuItems
