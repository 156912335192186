import { useQuery } from 'react-query';
import useCurrentUser from "./useCurrentUser";
import {getAIAgents} from "../api";

const useAIAgents = () => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('ai_agents', () => getAIAgents(token, userID), {
    refetchOnWindowFocus: true,
  });
  return result;
};

export default useAIAgents;
