import { useQuery } from 'react-query';
import useCurrentUser from "./useCurrentUser";
import {getFavorites} from "../api";

const useFavorites = (type) => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('favorites_'+type, () => getFavorites(type, token, userID), {
    refetchOnWindowFocus: true,
  });
  return result;
};

export default useFavorites;
