import React, {useMemo} from 'react'
import Screen from '../../components/Screen/Screen'
import {
    Box, Tooltip,
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";
import {prettyDate, prettyDateTime, truncate} from "../../lib/helpers/helpers";
import menuItems from "../../components/MainMenu/menuItems";
import useAIModels from "../../lib/hooks/useAIModels";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const LanguageModelsScreen = () => {
    const sidebarConfig = useMemo(()=> menuItems.find(item => item.slug === "ai-models")?.children,[menuItems])
    const overrideColumns = [
        {
            field: 'name',
            headerName: 'Model Name',
            width: 180,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'provider',
            headerName: 'Provider',
            width: 140,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'version',
            headerName: 'Version',
            width: 120,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 110,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                <span style={{ color: params.value === 'Active' ? 'green' : 'red' }}>
                    {params.value}
                </span>
                </Tooltip>
            )
        },
        {
            field: 'updated_at',
            headerName: 'Last Updated',
            width: 150,
            renderCell: (params) => {
                const content = params.value;
                return (
                    <Tooltip title={prettyDateTime(content)} placement="top">
                        <span>{prettyDate(content)}</span>
                    </Tooltip>
                );
            }
        },
        /*{
            field: 'capabilities',
            headerName: 'Capabilities',
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.value ? params.value?.join(', ') : 'N/A'}>
                    <span>{truncate(params.value ? params.value?.join(', ') : 'N/A', 100)}</span>
                </Tooltip>
            )
        }*/
    ];
    
    const {data, isLoading, refetch} = useAIModels()
    const demoData = [
        {
            id: 1,
            model_name: 'GPT-4o',
            provider: 'OpenAI',
            version: '4.0',
            status: 'Active',
            last_updated: '2023-08-01T12:00:00.000Z',
            capabilities: ['Text Generation', 'Translation', 'Summarization'],
        },
        {
            id: 2,
            model_name: 'Claude 3.5',
            provider: 'Anthropic',
            version: '3.5',
            status: 'Active',
            last_updated: '2023-07-15T09:00:00.000Z',
            capabilities: ['Text Completion', 'Code Generation', 'Language Understanding'],
        },
        {
            id: 3,
            model_name: 'Gemini 1.5',
            provider: 'Google DeepMind',
            version: '1.5',
            status: 'In Development',
            last_updated: '2023-05-20T08:30:00.000Z',
            capabilities: ['Text Analysis', 'Game Playing', 'Problem Solving'],
        },
        {
            id: 4,
            model_name: 'Llama 3.1',
            provider: 'Meta AI',
            version: '3.1',
            status: 'Deprecated',
            last_updated: '2022-11-10T14:20:00.000Z',
            capabilities: ['Conversation Handling', 'Content Filtering'],
        },
        {
            id: 5,
            model_name: 'Mixtral 8x22B',
            provider: 'Mistral AI',
            version: '8x22B',
            status: 'Active',
            last_updated: '2023-06-30T16:45:00.000Z',
            capabilities: ['Advanced NLP', 'Sentiment Analysis', 'Image Recognition'],
        },
        {
            id: 6,
            model_name: 'Inflection-2.5',
            provider: 'Inflection AI',
            version: '2.5',
            status: 'Active',
            last_updated: '2023-07-22T10:15:00.000Z',
            capabilities: ['Decision Making', 'Automated Reasoning'],
        },
        {
            id: 7,
            model_name: 'Jamba',
            provider: 'AI21 Labs',
            version: 'Latest',
            status: 'Active',
            last_updated: '2023-08-02T11:00:00.000Z',
            capabilities: ['Question Answering', 'Summarization'],
        },
        {
            id: 8,
            model_name: 'Command R',
            provider: 'Cohere',
            version: '1.0',
            status: 'In Development',
            last_updated: '2023-03-05T13:30:00.000Z',
            capabilities: ['Real-time Interactions', 'Contextual Responses'],
        },
        {
            id: 9,
            model_name: 'Grok',
            provider: 'xAI',
            version: '2.0',
            status: 'In Development',
            last_updated: '2023-03-05T13:30:00.000Z',
            capabilities: ['Real-time Interactions', 'Contextual Responses'],
        },
        {
            id: 10,
            model_name: 'Watson NLP',
            provider: 'IBM',
            version: '4.0',
            status: 'In Development',
            last_updated: '2022-03-05T13:30:00.000Z',
            capabilities: ['Real-time Interactions', 'Contextual Responses'],
        }
    ];

  return (
    <>
      <Screen title={'AI Models'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 2 }}>
            <EntityBrowser
               overrideColumns={overrideColumns} disableGridView={true}
               demoData={data || []} isLoading={isLoading} refetch={refetch}
            />
        </Box>
      </Screen>
    </>
  )
}

export default LanguageModelsScreen
