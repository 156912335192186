export const ENVIRONMENT = [
   ['development', window.location.origin.includes('localhost')],
   ['production', !window.location.origin.includes('localhost')]
].find(([name, isActive]) => isActive)[0]

// Dynamically detect the subdomain based on the script's `src` attribute
export const subdomain = (() => {
   let detectedSubdomain = null;
   
   // Use document.currentScript to detect the script's own src
   if (document.currentScript && document.currentScript.src) {
      const scriptSrc = document.currentScript.src;
      const url = new URL(scriptSrc);
      const hostnameParts = url.hostname.split('.');
      detectedSubdomain = hostnameParts.length > 2 ? hostnameParts[0] : null; // Extract subdomain
   } else {
      // Fallback to location.hostname if currentScript is unavailable
      const match = window.location.hostname.match(/^(.*?)\./); // Matches everything before the first dot
      detectedSubdomain = match ? match[1] : null;
   }
   
   return detectedSubdomain;
})();



// Derive APP_PUBLIC_PATH based on API_HOST
export const APP_PUBLIC_PATH = {
   development: '/static/', // Serve locally for dev
   staging: 'https://cdn.umbrella.ai/',
   production: 'https://cdn.umbrella.ai/'
}[ENVIRONMENT];



/*
export const ENVIRONMENT = [
  // always point native to production
  ['production', window.location.origin.includes('capacitor://localhost')],
  [
    'development',
    !window.location.origin.includes('capacitor://localhost') &&
      !window.location.origin.includes('index.umbrella.ai'),
  ],
  ['staging', window.location.origin.includes('staging.index.umbrella.ai')],
  [
    'production',
    !window.location.origin.includes('staging.index.umbrella.ai') &&
      window.location.origin.includes('index.umbrella.ai'),
  ],
].find(([name, isActive]) => isActive)[0]
*/

export const getSubdomainClientHost = (subdomain) =>
  ({
    development: `http://${subdomain}.localhost:3001`,
    staging: `https://${subdomain}.staging.index.umbrella.ai`,
    production: `https://${subdomain}.index.umbrella.ai`,
  })[ENVIRONMENT]

export const getClientHost = () =>
  ({
    development: `http://localhost:3003`,
    staging: `https://staging.index.umbrella.ai`,
    production: `https://index.umbrella.ai`,
  })[ENVIRONMENT]

export const CLIENT_HOST = {
    development: `http://localhost:3003`,
    staging: `https://staging.index.umbrella.ai`,
    production: `https://index.umbrella.ai`,
}[ENVIRONMENT]

export const API_HOST = {
   development: `http://localhost:5044/v1`,
   //development: 'https://aaahoosier.api.umbrella.ai/v1',
   staging: `https://${subdomain ? `${subdomain}.` : ''}staging.api.umbrella.ai/v1`,
   production: `https://${subdomain ? `${subdomain}.` : ''}api.umbrella.ai/v1`
}[ENVIRONMENT];

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: 'UA-137832774-xx',
  production: 'UA-137832774-xx',
}[ENVIRONMENT]
export const COLORS = ['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']
