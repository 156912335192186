import { useQuery } from 'react-query';
import useCurrentUser from "./useCurrentUser";
import {getAIModels} from "../api";

const useAIModels = () => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('ai_models', () => getAIModels(token, userID), {
    refetchOnWindowFocus: true,
  });
  return result;
};

export default useAIModels;
