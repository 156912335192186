import { useQuery } from 'react-query';
import useCurrentUser from "./useCurrentUser";
import {getKnowledgeBase} from "../api";

const useKnowledgeBase = (type = '') => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('knowledge_base_'+type, () => getKnowledgeBase(type, token, userID), {
    refetchOnWindowFocus: true,
  });
  return result;
};

export default useKnowledgeBase;
