import React, {useContext, useEffect} from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router-dom'
import './index.css'

import {
  SearchContextProvider,
  DemoContextProvider,
  OrganizationContextProvider,
  UserContextProvider,
  ThemeContext,
  OrganizationContext,
  ModeProvider,
  useMode,
  GlobalProvider,
  useGlobal, UserContext,
} from './lib/contexts'
import { ThemeProvider, StyledEngineProvider, Snackbar } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import useCurrentUser from './lib/hooks/useCurrentUser'
import { theme } from './lib/themes/theme'
import { Alert } from '@mui/lab'
import {MainMenu} from "./components/MainMenu/MainMenu";
//import Dashboard from './components/Dashboard/Dashboard'
import {
  AgentsScreen,
  ReportDashboard,
  OpenIndexAdmin,
  IntegrationsScreen,
  SearchScreen,
  ActionsScreen,
  KnowledgeBaseScreen,
  DataSourceScreen,
  CRMScreen,
  OrganizationScreen,
  LanguageModelsScreen,
  MapScreen,
  LoginScreen,
  Error404, InboxScreen, ChatScreen
} from './screens';
import EventsScreen from "./screens/EventsScreen";
import QuestionsAnswersScreen from "./screens/QuestionsAnswersScreen";
import {UniversalSearch} from "./components/UniversalSearch";
import {getClientHost} from "./lib/constants/constants";

function App() {
  const { mode } = useMode()
  const { snackbar, handleCloseSnackbar } = useGlobal()
  
  const {
    state: {
      user_id,
      plan,
      organization,
      phoneNumbers,
      showingSearch,
      showingHelp,
      showingAIWriter,
      showingAIImage,
      showPlanRequiredDialog,
      showOnboardRequiredDialog,
      showPhoneRequiredDialog,
      showVerifiedDomainRequiredDialog,
    },
    actions: {
      forceLogout,
      logout,
      setShowingSearch,
      setShowingHelp,
      setShowingAIWriter,
      setShowingAIImage,
      setShowPlanRequiredDialog,
      setShowOnboardRequiredDialog,
      setShowPhoneRequiredDialog,
      setShowVerifiedDomainRequiredDialog,
    },
  } = useContext(UserContext);
  
  
  useEffect(() => {
    const callback = event => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyF') {
        event.preventDefault();
        setShowingSearch(true);
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit1') {
        event.preventDefault();
        window.location.href = getClientHost() + '/search';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit2') {
        event.preventDefault();
        window.location.href = getClientHost() + '/browse';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit3') {
        event.preventDefault();
        window.location.href = getClientHost() + '/browse/people';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit4') {
        event.preventDefault();
        window.location.href = getClientHost() + '/browse/places';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit5') {
        event.preventDefault();
        window.location.href = getClientHost() + '/browse/conversations';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit6') {
        event.preventDefault();
        window.location.href = getClientHost() + '/sources';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit7') {
        event.preventDefault();
        window.location.href = getClientHost() + '/actions';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit8') {
        event.preventDefault();
        window.location.href = getClientHost() + '/settings';
      }
      if (event.code === 'Escape') {
        //  setShowingSearch(false)
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);
  return (
    <DemoContextProvider>
      <SearchContextProvider>
      <div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        <MainMenu />
        <div style={{
          transition: 'margin-left 300ms ease',
          width: '100%',
          overflow: 'hidden'
        }}>
        <Routes>
          {/*
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>*/}
          <Route path="/*" element={<SearchScreen />} />
          <Route path="/chat/:category?/:sub_category?/*" element={<ChatScreen />} />
          <Route path="/search/:category?/:sub_category?/*" element={<SearchScreen />} />
{/*
          <Route path="/people/:category?/:sub_category?/*" element={<CRMScreen />} />
*/}
          <Route path="/browse/people/:category?/:sub_category?/*" element={<CRMScreen />} />
          <Route path="/browse/persons/:category?/:sub_category?/*" element={<CRMScreen />} />
          <Route path="/browse/locations/:category?/:sub_category?/*" element={<MapScreen />} />
          <Route path="/browse/places/:category?/:sub_category?/*" element={<MapScreen />} />
          <Route path="/browse/events/:category?/:sub_category?/*" element={<EventsScreen />} />
          <Route path="/browse/questions_&_answers/:category?/:sub_category?/*" element={<QuestionsAnswersScreen />} />

          <Route path="/browse/:category?/:sub_category?/*" element={<KnowledgeBaseScreen />} />
          <Route path="/indexes/:category?/:sub_category?/*" element={<OpenIndexAdmin />} />
          <Route path="/integrations/:category?/:sub_category?/*" element={<IntegrationsScreen />} />
          <Route path="/actions/:category?/:sub_category?/*" element={<ActionsScreen />} />
          <Route path="/sources/:category?/:data_source_id?/*" element={<DataSourceScreen />} />
          <Route path="/models/:category?/:sub_category?/*" element={<LanguageModelsScreen />}></Route>
          <Route path="/agents/:category?/:sub_category?/*" element={<AgentsScreen />}></Route>
          <Route path="/organization/:category?/:sub_category?/*" element={<OrganizationScreen />} />
          <Route path="/organization" element={<OrganizationScreen />}></Route>
          <Route path="/reports" element={<ReportDashboard />}></Route>
          <Route path="/inbox" element={<InboxScreen />}></Route>
          <Route path="/profile" element={<Error404 />}></Route>
          <Route path="/settings" element={<Error404 />}></Route>
          <Route path="*" element={<Error404 />}></Route>
        </Routes>
          {(showingSearch) && <UniversalSearch />}
          <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert
                onClose={handleCloseSnackbar}
                severity={snackbar.severity}
                sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </div>
        </div>
      </SearchContextProvider>
    </DemoContextProvider>
  )
}
function AppProvider() {
  const { userID } = useCurrentUser()
  return <GlobalProvider>{userID ? <App /> : <LoginScreen />}</GlobalProvider>
}
const ThemeContainer = () => {
  const {
    state: { primary_color, secondary_color },
  } = useContext(OrganizationContext)
  return (
    <ThemeContext.Provider
      value={{
        darkMode: undefined,
        toggleTheme: () => {},
      }}
    >
      <StyledEngineProvider injectFirst>
        {/*<ThemeProvider theme={LightTheme(primary_color, secondary_color)}>*/}
        <ThemeProvider theme={theme}>
          <UserContextProvider>
            <AppProvider />
          </UserContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  )
}
const AppContainer = () => {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        variant={'info'}
        autoHideDuration={4000}
        preventDuplicate={true}
        maxSnack={6}
      >
        <OrganizationContextProvider>
          <ModeProvider>
            <ThemeContainer />
          </ModeProvider>
        </OrganizationContextProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  )
}
export default AppContainer
