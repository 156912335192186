import axios from 'axios'
import { API_HOST } from '../constants/constants'
export const updateUser = async (
  token,
  userID,
  options,
  otherUserId = undefined
) => {
  const apiURL = API_HOST + '/users/' + (otherUserId ?? userID)

  try {
    const { data } = await axios.patch(
      apiURL,
      { user: { ...options } },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          'x-user-id': userID,
        },
      }
    )
    return data
  } catch (error) {
    console.error(error)
  }
}
