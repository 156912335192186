import createGlobe from "cobe";
import { useEffect, useRef } from "react";
import {useSpring} from "react-spring";
import {hexToNormalizedRGB} from "../../lib/helpers/helpers";


export default function GlobeBrowser({fullSize = false, slowMode = true}) {
    const canvasRef = useRef();
    const pointerInteracting = useRef(null);
    const pointerInteractionMovement = useRef(0);
    const focusRef = useRef([0, 0])
    const [{ r }, api] = useSpring(() => ({
        r: 0,
        config: {
            mass: 1,
            tension: 280,
            friction: 40,
            precision: 0.001,
        },
    }));
    const locationToAngles = (lat, long) => {
        return [Math.PI - ((long * Math.PI) / 180 - Math.PI / 2), (lat * Math.PI) / 180]
    }
    
    const phiRef = useRef(0);
    const thetaRef = useRef(0);
    
    console.log(hexToNormalizedRGB('#DD2121'))
    useEffect(() => {
        phiRef.current = 0;   // horizontal rotation
        thetaRef.current = 0; // vertical rotation
        
        let width = 0;
        let currentPhi = 0;
        let currentTheta = 0;
        const doublePi = Math.PI * 2,
           markerSize = 0.045;
        const onResize = () => canvasRef.current && (width = canvasRef.current.offsetWidth)
        window.addEventListener('resize', onResize)
        onResize()
        
        const globe = createGlobe(canvasRef.current, {
            devicePixelRatio: 2,
            scale: fullSize ? 1.55 : 1.1,
            width: (fullSize ? 1500 : 800) * 2,
            height: (fullSize ? 1500 : 800) * 2,
            phi: 0,
            theta: 0,
            dark: 0.77, //1,
            opacity: 0.8,
            diffuse: 1.2,
            markerSize: markerSize,
            mapSamples: 10000,
            mapBrightness: 6,
            mapBaseBrightness: 0,
            baseColor: hexToNormalizedRGB('#1f2f96'), // [0.102, 0.133, 0.220]
            markerColor: hexToNormalizedRGB('#ffffff'), // 0.3,0.2,1 #DD2121
            glowColor: hexToNormalizedRGB('#83c1fc'), // [0.8,0.8,0.8],
            markers: [
                // longitude, latitude
                { location: [37.7595, -122.4367] },
                { location: [40.7128, -74.006] },
                { location: [37.78, -122.412] },
                { location: [52.52, 13.405] },
                { location: [35.676, 139.65] },
                { location: [-34.60, -58.38] },
                { location: [48.8566, 2.3522] },
                { location: [55.7558, 37.6173] },
                { location: [39.9042, 116.4074] },
                { location: [-33.8688, 151.2093] },
                { location: [34.0522, -118.2437] },
                { location: [28.6139, 77.209] },
                { location: [19.0760, 72.8777] },
                { location: [-23.5505, -46.6333] },
                { location: [1.3521, 103.8198] },
                { location: [41.0082, 28.9784] },
                { location: [51.5074, -0.1278] },
                { location: [35.6895, 139.6917] },
                { location: [23.1291, 113.2644] },
                { location: [22.3193, 114.1694] },
                { location: [31.2304, 121.4737] },
                { location: [25.2048, 55.2708] },
                { location: [-22.9068, -43.1729] },
                { location: [30.0444, 31.2357] },
                { location: [19.4326, -99.1332] },
                { location: [45.4215, -75.6972] },
                { location: [43.6532, -79.3832] },
                { location: [53.5461, -113.4938] },
                { location: [51.0447, -114.0719] },
                { location: [49.2827, -123.1207] },
                { location: [59.9139, 10.7522] },
                { location: [60.1699, 24.9384] },
                { location: [56.9496, 24.1052] },
                { location: [55.6761, 12.5683] },
                { location: [59.3293, 18.0686] },
            ].map((marker) => ({ ...marker, size: markerSize }))            ,
            onRender: (state) => {
                // Called on every animation frame.
                // `state` will be an empty object, return updated params.
                //state.phi = phi;
               // phi += 0.01;
                
                
                state.phi = currentPhi
                state.theta = currentTheta
                const [focusPhi, focusTheta] = focusRef.current
                const distPositive = (focusPhi - currentPhi + doublePi) % doublePi
                const distNegative = (currentPhi - focusPhi + doublePi) % doublePi
                // Control the speed
                if (distPositive < distNegative) {
                    currentPhi += distPositive * 0.08
                } else {
                    currentPhi -= distNegative * 0.08
                }
                   
                   currentTheta = currentTheta * 0.92 + focusTheta * 0.08
                
                // This prevents rotation while dragging
                if (!pointerInteracting.current) {
                    // Called on every animation frame.
                    // `state` will be an empty object, return updated params.
                    //phi += 0.005
                    phiRef.current += 0.005
                }
                let rotationSpeed = phiRef.current + r.get()
                state.phi = slowMode ? rotationSpeed / 4 : rotationSpeed
                state.theta = thetaRef.current
                state.width = width * 2
                state.height = width * 2
            }
        });
        
        return () => {
            globe.destroy();
            window.removeEventListener('resize', onResize);
        };
    }, []);
    
    return (
       <>
           <canvas
              ref={canvasRef}
              style={{marginTop: fullSize ? 0 : -100, width: 1000, height: 900, maxWidth: "100%", aspectRatio: 1,
                  cursor: 'grab',
                  contain: 'layout paint size',
                  transition: 'opacity 1s ease' }}
              
              onPointerDown={(e) => {
                  pointerInteracting.current =
                     e.clientX - pointerInteractionMovement.current;
                  canvasRef.current.style.cursor = 'grabbing';
              }}
              onPointerUp={() => {
                  pointerInteracting.current = null;
                  canvasRef.current.style.cursor = 'grab';
              }}
              onPointerOut={() => {
                  pointerInteracting.current = null;
                  canvasRef.current.style.cursor = 'grab';
              }}
              onMouseMove={(e) => {
                  if (pointerInteracting.current !== null) {
                      const delta = e.clientX - pointerInteracting.current;
                      pointerInteractionMovement.current = delta;
                      api.start({
                          r: delta / 200,
                      });
                     // thetaRef.current -= e.movementX / 200;
                  }
              }}
              onTouchMove={(e) => {
                  if (pointerInteracting.current !== null && e.touches[0]) {
                      const delta = e.touches[0].clientX - pointerInteracting.current;
                      pointerInteractionMovement.current = delta;
                      api.start({
                          r: delta / 100,
                      });
                     // thetaRef.current += -delta / 1000;
                     
                  }
              }}
           />
           
           <div className="flex flex-col md:flex-row justify-center items-center control-buttons" style={{ gap: '.5rem' }}>
               Rotate to:
               <button onClick={() => {
                   focusRef.current = locationToAngles(37.78, -122.412)
               }}>📍 San Francisco</button>
               <button onClick={() => {
                   focusRef.current = locationToAngles(52.52, 13.405)
               }}>📍 Berlin</button>
               <button onClick={() => {
                   focusRef.current = locationToAngles(35.676, 139.65)
               }}>📍 Tokyo</button>
               <button onClick={() => {
                   focusRef.current = locationToAngles(-34.60, -58.38)
               }}>📍 Buenos Aires</button>
           </div>
       
       </>
    );
}
