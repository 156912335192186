import React, {useCallback, useMemo} from 'react'
import Screen from '../../components/Screen/Screen'
import {
    Box, Tooltip,
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";
import {Cloud, DataArray, Favorite, Folder, Sync, Storage,PictureAsPdf} from "@mui/icons-material";
import {prettyDate, prettyDateTime, toCapitalizeFromUnderScore} from "../../lib/helpers/helpers";
import useDataSources from "../../lib/hooks/useDataSources";
import {useParams} from "react-router-dom";
import useFavorites from "../../lib/hooks/useFavorites";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const demoData = [
    { text: "Fuzeqna.com", tooltip: "https://www.fuzeqna.com/aaahoosier/ext/kbsearch.aspx", Icon: Sync, secondaryText: "22" },
    { text: "Hooser.aaa.com", tooltip: "https://www.hoosier.aaa.com/sitemap.xml", Icon: Sync, secondaryText: "0" },
    { text: "Calabrio", Icon: Sync, secondaryText: "0" },
    { text: "Current Member List", Icon: PictureAsPdf, secondaryText: "0" },
    { text: "Former Member List", Icon: PictureAsPdf, secondaryText: "0" },
    { text: "Prospective Member List", Icon: PictureAsPdf, secondaryText: "0" },
    { text: "S3 Bucket: Hoosier AAA", Icon: Cloud, secondaryText: "0" },
    { text: "Database: Hoosier AAA", Icon: Storage, secondaryText: "0" },
]

// Helper to pick the appropriate icon based on category and sync settings
const pickIcon = (category, syncEnabled) => {
    switch (category) {
        case 'db':
            return syncEnabled ? Sync : Storage;
        case 'cloud_s3':
            return Cloud;
        case 'file':
            return PictureAsPdf;
        default:
            return Storage;
    }
};

// Main function to map API response to the required format
const mapApiToSidebarData = (apiResponse) => {
    return apiResponse?.map((item) => ({
        text: item.name,
        path: '/sources/' + item.category +'/'+ item.id,
        tooltip: item.metadata?.tooltip || 'No tooltip available',
        Icon: pickIcon(item.category, item.sync_enabled) || Storage,
        secondaryText: item.sync_frequency || '0',
    }));
};
const mapToColumn = (columns) =>
   columns?.map((field) => {
       // Define default render logic for different fields
       const renderCell = (params) => {
           const { value } = params;
           
           if (field.includes('date') || field.includes('time')) {
               const content = value ? prettyDateTime(value) : 'Never';
               return (
                  <Tooltip title={content} placement="top">
                      <span>{value ? prettyDate(value) : 'Never'}</span>
                  </Tooltip>
               );
           }
           
           if (field.includes('active') || field.includes('enabled')) {
               return (
                  <Tooltip title={value ? 'Enabled' : 'Disabled'}>
            <span style={{ color: value ? 'green' : 'gray' }}>
              {value ? 'Yes' : 'No'}
            </span>
                  </Tooltip>
               );
           }
           
           if (typeof value === 'boolean') {
               return (
                  <Tooltip title={value ? 'True' : 'False'}>
            <span style={{ color: value ? 'green' : 'red' }}>
              {value ? 'Yes' : 'No'}
            </span>
                  </Tooltip>
               );
           }
           
           // Default render for text fields
           return (
              <Tooltip title={value || 'Not Set'}>
                  <span>{value || 'None'}</span>
              </Tooltip>
           );
       };
       
       // Return column definition
       return {
           field,
           headerName: toCapitalizeFromUnderScore(field),
           width: 150,
           renderCell,
       };
   });


const defaultColumns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 150,
        renderCell: (params) => (
           <Tooltip title={params.value}>
               <span>{params.value}</span>
           </Tooltip>
        )
    },
    {
        field: 'category',
        headerName: 'Category',
        width: 150,
        renderCell: (params) => (
           <Tooltip title={params.value}>
               <span>{toCapitalizeFromUnderScore(params.value)}</span>
           </Tooltip>
        )
    },
    {
        field: 'active',
        headerName: 'Active',
        width: 100,
        renderCell: (params) => (
           <Tooltip title={params.value ? 'Active' : 'Inactive'}>
                <span style={{ color: params.value ? 'green' : 'red' }}>
                    {params.value ? 'Yes' : 'No'}
                </span>
           </Tooltip>
        )
    },
    {
        field: 'sync_enabled',
        headerName: 'Sync Enabled',
        width: 120,
        renderCell: (params) => (
           <Tooltip title={params.value ? 'Enabled' : 'Disabled'}>
                <span style={{ color: params.value ? 'green' : 'gray' }}>
                    {params.value ? 'Yes' : 'No'}
                </span>
           </Tooltip>
        )
    },
    {
        field: 'last_synced_at',
        headerName: 'Last Synced At',
        width: 160,
        renderCell: (params) => {
            const content = params.value;
            return (
               <Tooltip title={content ? prettyDateTime(content) : 'Never Synced'} placement="top">
                   <span>{content ? prettyDate(content) : 'Never'}</span>
               </Tooltip>
            );
        }
    },
    {
        field: 'sync_frequency',
        headerName: 'Sync Frequency',
        width: 140,
        renderCell: (params) => (
           <Tooltip title={params.value || 'Not Set'}>
               <span>{params.value || 'None'}</span>
           </Tooltip>
        )
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 150,
        renderCell: (params) => {
            const content = params.value;
            return (
               <Tooltip title={prettyDateTime(content)} placement="top">
                   <span>{prettyDate(content)}</span>
               </Tooltip>
            );
        }
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        width: 150,
        renderCell: (params) => {
            const content = params.value;
            return (
               <Tooltip title={prettyDateTime(content)} placement="top">
                   <span>{prettyDate(content)}</span>
               </Tooltip>
            );
        }
    }
];

const DataSourceScreen = () => {
    
    
    const { category, data_source_id } = useParams();
    const { data, isLoading, refetch } = useDataSources(category ?? '', data_source_id)
    const { data: favoritesData, isLoading: favoritesIsLoading, refetch: favoritesRefetch } = useFavorites('DataSource')
    
    const chosenColumns = useMemo(()=> data_source_id ? mapToColumn(data?.columns) : defaultColumns, [data_source_id, data, defaultColumns])
    const chosenData = useMemo(()=> data_source_id ? data?.records : data, [data_source_id, data])
    
    const refreshAll = useCallback(() =>{
        refetch()
        favoritesRefetch()
    },[refetch, favoritesRefetch])
    
    
    
    const sidebarConfig = [
        {
            text: "Sources", Icon: Folder, secondaryText: data?.length ?? 0, children: data?.length ? mapApiToSidebarData(data) : [
                { text: "No Sources added" }
            ]
        },
        {
            text: "Favorites", Icon: Favorite, secondaryText: favoritesData?.length ?? 0, children: favoritesData?.length ? mapApiToSidebarData(favoritesData) : [
                { text: "No Favorites added" }
            ]
        },
        {
            text: "Exports", Icon: DataArray, children: [
                { text: "Schedule CSV Export" },
                { text: "Schedule JSON Export" },
                { text: "Schedule Cloud Export" },
            ]
        },
    ]
    
    return (
    <>
      <Screen title={'Data Sources'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 2 }}>
                  <EntityBrowser overrideColumns={chosenColumns} demoData={chosenData} isLoading={isLoading || favoritesIsLoading} refetch={refreshAll} />
        </Box>
      </Screen>
    </>
  )
}

export default DataSourceScreen
