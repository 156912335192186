import { useQuery } from 'react-query';
import useCurrentUser from "./useCurrentUser";
import {getDataSources} from "../api";

const useDataSources = (type = '', data_source_id = '') => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('data_source_'+type+'_'+data_source_id, () => getDataSources(type, data_source_id, token, userID), {
    refetchOnWindowFocus: true,
    staleTime: data_source_id ? 30 * 60000 : 100 * 60000 // 30 minutes for specific IDs, 100 minutes for general queries
  });
  return result;
};

export default useDataSources;
