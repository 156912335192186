import React from 'react'
import Screen from '../../components/Screen/Screen'
import {
  Box,
  Grid,
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";
import useKnowledgeBase from "../../lib/hooks/useKnowledgeBase";
import {useParams} from "react-router-dom";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const KnowledgeBaseScreen = () => {
   const { category } = useParams();
   const { data, isLoading, refetch } = useKnowledgeBase(category)
  return (
    <>
      <Screen title={'Search'}>
        <Box sx={{ transitionStyle, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={transitionStyle}>
                  <EntityBrowser demoData={data} isLoading={isLoading} refetch={refetch} />
                </Grid>
              </Grid>
        </Box>
      </Screen>
    </>
  )
}

export default KnowledgeBaseScreen
